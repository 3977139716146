<!-- Mto particular de Compañías Sucursales -->

<template>
  <div class="cias_M"  v-if="schema">
    
    <!-- Cabecera -->
    <div class="cabecera">
      <base_Header
        v-if="Entorno.header.header"       
        :Entorno="Entorno.header"
        @onEvent="event_Header">
      </base_Header>
    </div>

    <v-sheet :elevation="4">
       <!-- Slot con los controles del Mto -->
      <div class="contenedor" style="width:1200px">
        <div class="pt-2 d-flex justify-left" style="justify-content:space-between;width:320px">
          <!-- Botones Mto -->        
          <btramto
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="m"
              :estado="estado"
              @onEvent="event_btra_Mto">        
          </btramto>

          <!-- Ventana de Documentos -->
          <common_Docs
            :padre="stName"
            :id="record.id"       
            show="1"
            tip="2">
          </common_Docs>

          <!-- Ventana de Facturas -->
          <common_Fras
            :padre="stName"
            :id="record.id"       
            show="1"
            tip="2">
          </common_Fras>
        </div>

        <div class="conflex">
          <div class="columna" style="width:59%">
            <div class="conflex">
              <ctrlcontact 
                style="flex: 1 1 50%"
                :schema="schema" 
                :edicion="is_edit">
              </ctrlcontact>
            </div>

            <div class="cab">DATOS COMPAÑÍA</div>
            <v-sheet v-bind="$cfg.styles.marco">          
              <div class="conflex"> 
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.cont1.value"
                  :label="schema.ctrls.cont1.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.cont2.value"
                  :label="schema.ctrls.cont2.label"
                  :disabled="!is_edit"
                ></v-text-field>
              
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.cont3.value"
                  :label="schema.ctrls.cont3.label"
                  :disabled="!is_edit"
                ></v-text-field>
              </div>

              <div class="conflex"> 
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.email1.value"
                  :label="schema.ctrls.email1.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.email2.value"
                  :label="schema.ctrls.email2.label"
                  :disabled="!is_edit"
                ></v-text-field>
              </div>
               
              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.admoncont.value"
                :label="schema.ctrls.admoncont.label"
                :disabled="!is_edit"
              ></v-text-field>

              <div class="conflex">                
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.admontlf1.value"
                  :label="schema.ctrls.admontlf1.label"
                  :disabled="!is_edit"
                ></v-text-field>
              
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.admonfax1.value"
                  :label="schema.ctrls.admonfax1.label"
                  :disabled="!is_edit"
                ></v-text-field>
                  
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.admonemail.value"
                  :label="schema.ctrls.admonemail.label"
                  :disabled="!is_edit"
                ></v-text-field>
              </div>

              <div class="conflex">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.banco.value"
                  :label="schema.ctrls.banco.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.cuenta.value"
                  :label="schema.ctrls.cuenta.label"
                  :disabled="!is_edit"
                ></v-text-field>
              </div>
            </v-sheet>

            <ctrlobs3
              :schemaComponente="schema.ctrls.obs"
              :schema="schema"   
              :record="accionRow"
              :edicion="is_edit">
            </ctrlobs3>
          </div>

          <div class="columna" style="width:40%">
            <div class="cab">CLASIFICACIÓN</div>
            <v-sheet v-bind="$cfg.styles.marco">
              <div class="conflex">
                <v-select
                  :items="itemsSitu"
                  :disabled="!is_edit"
                  item-value="id"
                  item-text="label"
                  v-bind="$select"
                  v-model="schema.ctrls.situ.value"
                ></v-select>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.ref_cia.value"
                  :label="schema.ctrls.ref_cia.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.ccont.value"
                  :label="schema.ctrls.ccont.label"
                  :disabled="!is_edit"
                ></v-text-field>
              </div>
            </v-sheet>

            <div class="cab">CENTRAL</div>
            <v-sheet v-bind="$cfg.styles.marco">       
              <ctrlfinder
                finderName="ciac_F" 
                :schema="schema.ctrls.ciac"
                :edicion="is_edit">
              </ctrlfinder>
            </v-sheet>

            <div class="cab">GRUPO</div>
            <v-sheet v-bind="$cfg.styles.marco">
              <div class="conflex">
                <v-text-field
                  style="flex: 1 1 33%"
                  v-bind="$input"
                  v-model="schema.ctrls.tlf1.value"
                  :label="schema.ctrls.tlf1.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  style="flex: 1 1 33%"
                  v-bind="$input"
                  v-model="schema.ctrls.tlf2.value"
                  :label="schema.ctrls.tlf2.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  style="flex: 1 1 33%"
                  v-bind="$input"
                  v-model="schema.ctrls.tlf3.value"
                  :label="schema.ctrls.tlf3.label"
                  :disabled="!is_edit"
                ></v-text-field>
              </div>

              <div class="conflex">
                <v-text-field
                  style="flex: 1 1 33%"
                  v-bind="$input"
                  v-model="schema.ctrls.tlf4.value"
                  :label="schema.ctrls.tlf4.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  style="flex: 1 1 33%"
                  v-bind="$input"
                  v-model="schema.ctrls.tlf5.value"
                  :label="schema.ctrls.tlf5.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  style="flex: 1 1 33%"
                  v-bind="$input"
                  v-model="schema.ctrls.tlf6.value"
                  :label="schema.ctrls.tlf6.label"
                  :disabled="!is_edit"
                ></v-text-field>
              </div>

              <div class="conflex">
                <v-text-field
                  style="flex: 1 1 33%"
                  v-bind="$input"
                  v-model="schema.ctrls.fax1.value"
                  :label="schema.ctrls.fax1.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  style="flex: 1 1 33%"
                  v-bind="$input"
                  v-model="schema.ctrls.fax2.value"
                  :label="schema.ctrls.fax2.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  style="flex: 1 1 33%"
                  v-bind="$input"
                  v-model="schema.ctrls.fax3.value"
                  :label="schema.ctrls.fax3.label"
                  :disabled="!is_edit"
                ></v-text-field>
              </div>

              <ctrlurl :schema="schema.ctrls.url" :edicion="is_edit"></ctrlurl>
            </v-sheet>
          </div>
        </div>
      </div>
    </v-sheet>
  </div>
</template>



<script>
  import { mixinMto } from "@/mixins/mixinMto.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const btramto = () => plugs.groute("btramto_new.vue", "comp");
  const ctrlcontact = () => plugs.groute("ctrlcontact.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const ctrlurl = () => plugs.groute("ctrlurl.vue", "comp");
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");
  const common_Docs = () => plugs.groute("common_Docs.vue", "comp");
  const common_Fras = () => plugs.groute("common_Fras.vue", "comp");

  export default {
    mixins: [mixinMto],
    components: {
      base_Header,
      btramto,
      ctrlcontact,
      ctrlfinder,
      ctrlurl,
      ctrlobs3,
      common_Docs,
      common_Fras
    },

    props: {
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: null},
      disparo: { type:Boolean, default:false },      
      componenteTipo: { type:String, default:'M' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'cias_M',
        stName:'stMcias',
        disparoFinder:false, 
       
        itemsSitu:[]
      };
    },



    methods: {
      ini_data(){
        console.log(" dev ********************** cias_M ini_data");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;  

        this.Entorno.header.header= true;
        this.Entorno.header.titulo = "Compañías Sucursales";
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.standardM));

        // items adicionales
        //aray de situación
        this.itemsSitu=this.$store.state.datos_iniciales.ciasitu;
      },

    }
  };
</script>
